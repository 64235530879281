<script setup>
	import {
		ref,
		computed,
		watch,
	} from 'vue';
	import { DsText, DsButton, DsIconButton, DsButtonGroup } from "coloplast-design-system";
	import Events from '../settings/events';
	import CheckoutModalLogin from 'multisite/components/checkout/checkoutModalLogin.vue';
	import PrescriptionModal from 'shared/components/product/prescriptionModal.vue';
	import SampleConsentModal from 'shared/components/product/sampleConsentModal.vue';
	import ConsentConfirmationService from 'shared/services/consentConfirmation.service';
	import { useStore } from 'vuex';

	const
		showModal = ref(true),
		vm = ref(null),
		consentsState = ref({}),
		prescriptionModalData = ref(null),
		showPrescriptionModal = ref(true),
		customerModel = ref(null);

	const
		store = useStore(),
		language = computed(() => store.getters.getCulture);

	const props = defineProps({
		modalData: {
			type: Object,
			default: () => { },
		},
	});

	watch(() => props.modalData, () => {
		setVmModelForModal();
		showModal.value = true;
	}, { deep: true });

	function toggleModal() {
		showModal.value = !showModal.value;
	}

	function openFlyOutBasket() {
		const openFlyoutBasket = new CustomEvent(Events.ACTIVATE_FLY_OUT_BASKET, {});
		document.dispatchEvent(openFlyoutBasket);
		toggleModal();
	}

	function setVmModelForModal() {
		if (props.modalData?.modalType != 'ValidationError') {
			const modalType = props.modalData.modalType;
			//transform json to model we need for checkout login modal
			if (modalType === 'AuthorizationRequired') {
				prescriptionModalData.value = null;
				customerModel.value = null;
				vm.value = 	{
					BaseViewModel: {
						AuthenticateModalViewModel: {
							IsHcpModal: props.modalData.isHcpModal,
							CurrentContent: setUpperCaseFirstLetter()
						}
					}
				};
			}
			//prescription modal model
			if (modalType === 'PrescriptionRequired') {
				vm.value = null;
				customerModel.value = null;
				getPresciptionConsentState();			
			}
			//hcp/consumer selection popup
			if (modalType === 'CustomerSelectionRequired') {
				customerModel.value = setUpperCaseFirstLetter();
				vm.value = null;
				prescriptionModalData.value = null;
			}
		}
		else {
			vm.value = null;
			prescriptionModalData.value = null;
			customerModel.value = null;
		}
	}
	setVmModelForModal();

	function setUpperCaseFirstLetter() {
		return Object.assign(...Object.keys(props.modalData).map(key => ({ [key.charAt(0).toUpperCase() + key.slice(1)]: props.modalData[key] })));
	}

	function cancelPrescriptionModal() {
		showPrescriptionModal.value = false;
		consentsState.value.prescriptionConsentAccepted = false;
	}

	async function addItemToBasket(updateConsent = true) {
		if (updateConsent) {
			await updateConsentsState();
		}
		const payload = {
			Code: props.modalData.parameter,
			IsCuttingServiceChecked: false,
			IsSample: true,
			LineItemSource: 0,
			Quantity: 1,
			TrackingParameters: {},
		}

		const response = await store.dispatch('addItemToBasket', payload);
		if (response?.status === 200) {
			openFlyOutBasket();
		}

		showPrescriptionModal.value = false;
	}

	async function getConsentsState() {
		const response = await ConsentConfirmationService.checkConsentConfirmation({
			Language: language.value,
			Code: props.modalData.parameter,
		});

		if (response?.data?.responseData?.sampleConsent) {
			consentsState.value = response.data.responseData.sampleConsent
		}
	}

	async function getPresciptionConsentState() {
		await getConsentsState();

		if (consentsState.value.prescriptionConsentAccepted) {
			addItemToBasket(false);
		}
		else {
			prescriptionModalData.value = {
				dataName: 'review-quantity',
				tabindex: 1
			}
			showPrescriptionModal.value = true;
		}
	}

	async function updateConsentsState() {
		await ConsentConfirmationService.updateConsentConfirmation({
			Code: props.modalData.parameter,
			Language: language.value,
			SampleConsent: {
				HcpConsentAccepted: consentsState.value.hcpConsentAccepted,
				ConsumerConsentAccepted: consentsState.value.consumerConsentAccepted,
				PrescriptionConsentAccepted: consentsState.value.prescriptionConsentAccepted
			}
		});
	}

	async function handleConsumer() {
		await getConsentsState();

		if (consentsState?.value) {
			consentsState.value.consumerConsentAccepted = true;
			consentsState.value.hcpConsentAccepted = false;
		}

		const isRedirectionConfirmed = customerModel?.value?.ShowHcpOptionFirst;
		await handleEventComplete(isRedirectionConfirmed);
	}

	async function handleHcp() {
		await getConsentsState();

		if (consentsState?.value) {
			consentsState.value.consumerConsentAccepted = false;
			consentsState.value.hcpConsentAccepted = true;
		}

		const isRedirectionConfirmed = !customerModel?.value?.ShowHcpOptionFirst;
		await handleEventComplete(isRedirectionConfirmed);
	}

	async function handleEventComplete(isRedirectionConfirmed) {
		await updateConsentsState();
		if (!customerModel.value.DontAddProductToBasket && !isRedirectionConfirmed) {
			await addItemToBasket(false);
		}

		if (isRedirectionConfirmed && customerModel?.value?.RedirectPageUrl) {
			window.location.assign(customerModel.value.RedirectPageUrl);
		}
	}
</script>

<template>
	<div class="c-modal__mask c-modal__mask--grey" @click="toggleModal()" @kedown.enter="toggleModal()" @keydown.escape="toggleModal()" v-if="showModal && !vm && !prescriptionModalData && !customerModel">
		<div class="c-modal__wrapper c-modal__wrapper--generic-modal c-modal__wrapper--samplingerrors" :tabindex="showModal ? 1 : -1" @click.stop>
			<div class="c-modal__closewrapper">
				<DsIconButton variant="tertiary" icon-name="close" size="md" :click-handler="() => { toggleModal() }"></DsIconButton>
			</div>
			<DsText htmlElement="h2" text-type="heading-2xl" text-style="bold" v-html="modalData.errorHeadline"></DsText>
			<DsText htmlElement="div" text-type="body-md" text-style="regular" v-html="modalData.errorMessage"></DsText>
			<DsButtonGroup mobileDirection="column" desktop-alignment="start" desktop-direction="row" class="popup__button-group">
				<DsButton variant="primary" v-if="!modalData.basketButtonText && modalData.closeButtonText" :click-handler="() => { toggleModal() }">{{ modalData.closeButtonText }}</DsButton>
				<template v-else>
					<DsButton variant="primary" :click-handler="() => { openFlyOutBasket() }" v-if="modalData.basketButtonText">{{ modalData.basketButtonText }}</DsButton>
					<DsButton :click-handler="() => { toggleModal() }" v-if="modalData.closeButtonText" variant="link">{{ modalData.closeButtonText }}</DsButton>
				</template>
			</DsButtonGroup>
		</div>
	</div>
	<CheckoutModalLogin v-if="vm"
						:show="showModal"
						:tabindex="3"
						:vm="vm"
						:signup-return-url="modalData.signUpUrl"
						:signin-url-prop="modalData.signInUrl">
	</CheckoutModalLogin>
	<PrescriptionModal v-if="prescriptionModalData && showPrescriptionModal"
					   :modal-data="prescriptionModalData"
					   :modal-labels="modalData"
					   :consents-state="consentsState"
					   @close="showPrescriptionModal = !showPrescriptionModal"
					   @cancel="cancelPrescriptionModal()"
					   @continue="addItemToBasket()">
	</PrescriptionModal>
	<SampleConsentModal v-if="customerModel"
						:vm="customerModel"
						:show="showModal"
						:tabindex="3"
						:redirect-page-url="customerModel.RedirectPageUrl"
						:show-hcp-option-first="customerModel.ShowHcpOptionFirst"
						@consumer="handleConsumer()"
				        @hcp="handleHcp()"
						@discontinue="toggleModal()">
	</SampleConsentModal>
</template>

