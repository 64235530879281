<script setup>
import SkeletonWrapper from 'shared/components/skeleton/skeletonWrapper.vue';
import SkeletonBox from 'shared/components/skeleton/skeletonBox.vue';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const areImagesLoaded = computed(() => store.getters['getGalleryItems']);
const imageGalleryElement = ref(document.getElementById('image-gallery'));
const isDisplayManufacturerLogo = computed(() => store.getters['getDisplayManufacturerLogo']);
const isGalleryMounted = computed(() => imageGalleryElement.value && areImagesLoaded.value.length || isDisplayManufacturerLogo.value);
const isSkeletonCreated = true;
</script>

<template>
	<skeleton-wrapper class="c-skeleton__image-gallery">
		<slot v-if="!isSkeletonCreated"></slot>
		<div v-if="!isGalleryMounted" v-for="element in 4" class="c-skeleton__image-gallery__wrapper"
			data-testid="skeleton-image">
			<skeleton-box width="100%" aspectRatio="1"></skeleton-box>
		</div>
	</skeleton-wrapper>
</template>

<style lang="scss">
@import "src/shared/styles/settings/settings";
@import "src/shared/styles/tools/tools";

.c-skeleton__image-gallery {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
	height: 100%;
	padding-left: ds-space(4);
	padding-right: ds-space(14);

	&__wrapper {
		width: 50%;
		aspect-ratio: 1;
		padding: 0 ds-space(5) ds-space(8);
	}

	.c-skeleton-box {
		border-radius: 8px;
	}
}
</style>
