<template>
	<div
		class="c-modal__mask c-modal__mask--grey"
		v-if="modalState"
	>
		<div
			class="c-modal__wrapper c-modal__wrapper--form-signup"
			:tabindex="tabindex"
			data-testid="consents-modal"
		>
			<div class="c-modal__container" v-if="vm">
				<DsIconButton
					@click="close"
					@keydown.enter.prevent="close"
					:tabindex="tabindex"
					class="c-modal__close--from-ds-icon-button"
  				  	aria-label="Close button"
  				  	icon-name="close"
  				  	variant="tertiary"
					data-testid="close-button"
  				/>
				<DsText
					v-if="vm.Headline"
					v-text="vm.Headline"
					html-element="h2"
					text-type="heading-2xl"
					text-style="bold"
					data-testid="consents-modal__headline"
					class="consents-modal__headline">
				</DsText>
				<DsText
					v-if="vm.DescriptionText"
					html-element="div"
					text-type="body-lg"
					data-testid="consents-modal__description">
					<div v-html="vm.DescriptionText" />
				</DsText>
				<DsText
					v-if="vm.SelectorHeadline"
					html-element="h3"
					text-type="heading-1xl"
					text-style="bold"
					data-testid="consents-modal__selector-headline">
					<div v-html="vm.SelectorHeadline" />
				</DsText>
				<ul class="c-modal__sample-options">
				<li class="box" v-for="(option, index) in consentOptions" :key="option.id">
					<input
						v-model="modalChoice"
						type="radio"
						name="address"
						:id="option.id"
						:value="index"
						:data-testid="option.testId"
						:index="index"
						class="sample-option__radio"
						/>
					<label :for="option.id" :role="option.id" class="sample-option__label">
						<span>{{ option.text }}</span>
						<div v-if="index === 1" v-html="redirectionHint"></div>
						<div v-if="index === 2" v-html="hcpOnBehalfRedirectionHint"></div>
					</label>
				</li>
				</ul>

				<DsButtonGroup desktopAlignment="left" desktop-direction="column">
					<div v-if="proceedWithoutRegistering">
						<slot name="proceed"></slot>
					</div>

					<DsButton
							  v-if="modalChoice === 0 && !proceedWithoutRegistering"
							  @click.prevent="trackLoginChoice()"
							  @keydown.enter.prevent="trackLoginChoice()"
							  :tabindex="tabindex"
							  variant="primary"
							  size="lg"
							  style="margin-bottom: 0;"
							  data-testid="consents-modal__add-to-basket-test">
						{{ vm.AddToBasketButtonLabel }}
					</DsButton>

					<DsButton
							  v-if="modalChoice === 1 && redirectPageUrl && vm.RedirectButtonLabel"
							  @click.prevent="trackLoginChoice()"
							  @keydown.enter.prevent="trackLoginChoice()"
							  :tabindex="tabindex"
							  variant="primary"
							  size="lg"
							  data-testid="consents-modal__redirect-test">
						{{ vm.RedirectButtonLabel }}
					</DsButton>

					<!--Hcp on behalf of patient-->
					<DsButton v-if="modalChoice === 2 && hcpOnBehalfUrl && vm.HcpBehalfRedirectButtonLabel"
							  @click.prevent="trackLoginChoice()"
							  @keydown.enter.prevent="trackLoginChoice()"
							  :tabindex="tabindex"
							  variant="primary"
							  size="lg"
							  data-testid="consents-modal__behalf-test">
						{{ vm.HcpBehalfRedirectButtonLabel }}
					</DsButton>
				</DsButtonGroup>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { ref, watch, onMounted } from 'vue';
	import { DsButton, DsIconButton, DsButtonGroup, DsText } from "coloplast-design-system";
	import { track_dual_checkout_choice } from 'shared/services/tracking/formTrackingService';

	const
		modalRole = {
			CONSUMER: "consumer",
			HCP: "hcp",
			HcpOnBehalfPatient: "hcp on behalf a patient"
		},
		MODAL_ACTION_DISCONTINUE = "discontinue";

	const emit = defineEmits(["discontinue", "consumer", "hcp"]);

	const props = defineProps({
		vm: {
			type: Object
		},
		tabindex: {
			type: Number,
			default: 0
		},
		show: {
			type: Boolean,
			default: false
		},
		proceedWithoutRegistering: {
			type: Boolean,
			default: false
		},
		showHcpOptionFirst: {
			type: Boolean,
			default: false
		},
		redirectPageUrl: {
			type: String,
			default: ''
		},
		enableHcpOnBehalfOption: {
			type: Boolean,
			default: false
		},
		hcpOnBehalfUrl: {
			type: String,
			default: null,
		}
	}),
		modalState = ref(props.show),
		sampleOptionChecked = ref(false),
		modalChoice = ref(null),
		consentOptions = ref([]),
		consentOrderNames = ref([]),
		redirectionHint = ref(props?.vm?.RedirectOptionSupportingText),
		hcpOnBehalfRedirectionHint = ref(props?.vm?.HcpBehalfRedirectOptionSupportingText);

	function trackLoginChoice() {
		if (modalChoice.value === null) return;

		const consentTrackingRoles = (props.showHcpOptionFirst)
			? [modalRole.HCP, modalRole.CONSUMER, modalRole.HcpOnBehalfPatient]
			: [modalRole.CONSUMER, modalRole.HCP, modalRole.HcpOnBehalfPatient];
		const consentTrackingRole = consentTrackingRoles[modalChoice.value]

		track_dual_checkout_choice(consentTrackingRole);
		modalState.value = false;
		if (modalChoice.value != 2) {
			emit(consentTrackingRole);
		}
		else {
			window.location.href = props.hcpOnBehalfUrl;
		}
	}

	function close() {
		modalState.value = false;
		emit(MODAL_ACTION_DISCONTINUE);
	}

	watch(() => props.vm, () => {
		modalState.value = props.show;
	}, { deep: true });

	onMounted(() => {
		const hcpOption = {
			id: modalRole.HCP,
			text: props.vm?.HcpOptionText,
			testId: `consents-modal__${modalRole.HCP}`
		};
		const consumerOption = {
			id: modalRole.CONSUMER,
			text: props.vm?.ConsumerOptionText,
			testId: `consents-modal__${modalRole.CONSUMER}`
		};

		const hcpOnBehalfOfPatient = {
			id: 'hcpOnBehalf',
			text: props.vm?.HcpBehalfOptionText,
			testId: 'consents-modal__hcpOnBehalf'
		}

		consentOptions.value = (props.showHcpOptionFirst)
			? [hcpOption, consumerOption, hcpOnBehalfOfPatient]
			: [consumerOption, hcpOption, hcpOnBehalfOfPatient];
        //if third option 'hcp on behalf of patient' is not enabled
		if (!props.enableHcpOnBehalfOption || !props.hcpOnBehalfUrl) {
			consentOptions.value.splice(-1);
		}
	});
</script>

<style lang="scss" scoped>
@import '../../styles/settings/settings';
@import '../../styles/tools/tools';

	.c-modal__wrapper {
		@include t-respond-to-min(medium) {
			padding-left: ds-space(15);
			padding-right: ds-space(15);
		}

		.c-modal__container {
			@include t-respond-to-min(medium) {
				max-width: 100%;
			}
			.ds-text-body-lg {
				font-size: t-rem(16px);
			}

			h2 {
				color: #213D46;
				font-size: t-rem(24px);

				@include t-respond-to-min(medium) {
					margin-bottom: ds-space(10)
				}
			}

			h3 {
				color: #213D46;
				font-size: t-rem(16px);
				margin-top: ds-space(9);
				margin-bottom: ds-space(5);

				@include t-respond-to-min(medium) {
					font-size: t-rem(18px);
				}
			}

			.c-modal__sample-options {
				padding: 0;

				li {
					position: relative;
					list-style-type: none;
					padding-left: ds-space(10);
					padding-bottom: ds-space(5);

					.sample-option__radio {
						position: absolute;
						left: -100px;
						display: none;
					}

					.sample-option__label {
						font-size: t-rem(16px);
						font-weight: ds-font-weight("regular");

						div {
							font-size: t-rem(14px);
							margin-top: ds-space(3);
							line-height: 1.8;
						}
					}

					input[type=radio]:checked+.sample-option__label {
						&:before {
							height: 12px;
							width: 12px;
							background-color: var(--ds-color-bg-default);
							border: 7px solid var(--ds-color-border-interactive);
						}

						div {
							display: block;
						}
					}

					input[type=radio]+.sample-option__label {
						&:before {
							content: "";
							display: block;
							height: 24px;
							width: 24px;
							border-radius: 50%;
							border: 1px solid var(--ds-color-border-strong);
							position: absolute;
							top: 1px;
							left: 1px;
						}

						div {
							display: none
						}
					}
				}
			}
		}
	}
</style>
