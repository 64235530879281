<template>
	<DsText
		v-if="count > 0"
		class="c-nav-basket__count"
		html-element="span"
		text-type="body-xs"
		text-style="bold"
		aria-hidden="true">
		{{count}}
	</DsText>
</template>
<script>
	import { mapGetters } from 'vuex';
	import { DsText } from 'coloplast-design-system';

	export default {
		props: {
			basketCount: {
				default: 0
			}
		},
		components: {
			DsText
		},
		computed: {
			...mapGetters({
				numberOfBasketItems: 'getBasketItemCount',
			}),
			count() {
				if (this.numberOfBasketItems == 0) {
					return this.basketItemCount;
				}
				else {
					return this.numberOfBasketItems;
				}
			}
		},
		data() {
			return {
				basketItemCount: this.basketCount
			}
		},
		mounted() {
			this.$eventBus.$on('itemsAddedToTheBasket', (result) => {
				this.basketItemCount = result.basketItemCount;
			});
		},
		watch: {
			numberOfBasketItems(newValue) {
				this.basketItemCount = newValue;
			}
		}
	}
</script>
