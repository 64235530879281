<script setup>
import { ref } from 'vue';
import { DsBreadcrumb } from "coloplast-design-system";
import "coloplast-design-system/dist/components/breadcrumb/ds-breadcrumb.css";
import "coloplast-design-system/dist/components/tooltip/ds-tooltip.css";

const props = defineProps({
	vm: Object
});

const breadcrumbItems = ref(props.vm.BaseViewModel.map(breadcrumbItem => {
	return {
		name: breadcrumbItem.Name,
		url: breadcrumbItem.Link,
		isCurrentPage: breadcrumbItem.Active
	}
}));
</script>

<template>
	<div v-if="breadcrumbItems.length" class="c-breadcrumb__container"
		:class="{ 'c-breadcrumb__container--hidden': breadcrumbItems.length <= 1 }">
		<DsBreadcrumb
			ariaLabel="Breadcrumb"
			:items="breadcrumbItems"
			overflowMenuTooltipText="More items" />
	</div>
</template>

<style lang="scss" scoped>
@import "src/shared/styles/settings/settings";
@import "src/shared/styles/tools/tools";

$breakpoint: 'medium';
$profilePageBreakpoint: 900px;

.c-breadcrumb {
	background: ds-color("bg", "default");

	&--with-background {
		background: ds-color("bg", "decorative-1-subtle");
	}

	&--hide-mobile {
		@include t-respond-to-max($profilePageBreakpoint) {
			display: none;
		}
	}

	&__container {
		display: flex;
		flex: 1 0 auto;
		flex-wrap: wrap;
		margin: 0 auto;
		max-width: ds-container-max("2xl");
		width: 100%;
		padding: 0 ds-space(6);

		&--hidden {
			display: none;

			@include t-respond-to-min($breakpoint) {
				display: flex;
			}
		}

		@media (max-width: ds-breakpoint("xl")) {
			padding: 0 ds-space(5);
		}

		@media (max-width: ds-breakpoint("md")) {
			padding: 0 ds-space(7);
		}
	}
}

</style>

<style lang="scss">
@import "src/shared/styles/tools/tools";
.c-breadcrumb__container {
	.ds-breadcrumb__list {
		margin: 0;
		padding: 0;
	}
}
</style>
